/* FONT PATH
 * -------------------------- */

@font-face {
    font-family: 'ProximaNovaRegular';
    src: url("#{$font-path}proximanova-regular-webfont.eot");
    src: url("#{$font-path}proximanova-regular-webfont.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}proximanova-regular-webfont.woff") format("woff"),
        url("#{$font-path}proximanova-regular-webfont.ttf") format("truetype"),
        url("#{$font-path}proximanova-regular-webfont.svg#ProximaNovaSemibold") format("svg"); font-weight: normal; font-style: normal;
    }

@font-face {
    font-family: 'ProximaNovaSemibold';
    src: url("#{$font-path}proximanova-semibold-webfont.eot");
    src: url("#{$font-path}proximanova-semibold-webfont.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}proximanova-semibold-webfont.woff") format("woff"),
        url("#{$font-path}proximanova-semibold-webfont.ttf") format("truetype"),
        url("#{$font-path}proximanova-semibold-webfont.svg#ProximaNovaSemibold") format("svg");
        font-weight: normal;
        font-style: normal;
    }

@font-face {
    font-family: 'PrivaOneProRegular';
    src: url("#{$font-path}privaonepro-webfont.eot");
    src: url("#{$font-path}privaonepro-webfont.eot?#iefix") format("embedded-opentype"),
        url("#{$font-path}privaonepro-webfont.woff") format("woff"),
        url("#{$font-path}privaonepro-webfont.ttf") format("truetype"),
        url("#{$font-path}privaonepro-webfont.svg#PrivaOnePro") format("svg");
        font-weight: normal;
        font-style: normal;
    }

