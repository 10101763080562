html,
body {
    width: 100%;
    height: 100%;
    min-height: auto;
    background-color: black;
}


 .navbar {
    margin-bottom: 0;
}

.navbar-40 {
    background-color: #003A70;
    color: #ffffff;
    border-radius: 0;
}

.navbar-40 .navbar-nav > li > a {
    color: #fff;
}

/*
 *
 * Reduce size of navigation on small screens
 */
@media only screen and (min-width : $screen-sm) and (max-width : $screen-sm-max) {
    .navbar-40 .navbar-nav > li > a {
        font-size: 12px;
        padding: 15px 6px;
    }
}

.navbar-40 .navbar-nav > .active > a, .navbar-nav > .active > a:hover, .navbar-nav > .active > a:focus {
    color: #ffffff;
    background-color:transparent;
}

.navbar-40 .navbar-brand {
    color:#eeeeee;
}

.navbar-40 .navbar-toggle .icon-bar {
    background-color: #fff;
}

.navbar-40 .navbar-toggle {
    border: 0;
}

.navbar-brand > img {
    height: 25px;
    width: auto;
}

.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: #91B0D5;
}

.btn-concept2 {
    color: #111;
    background-color: #AFBD22;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-concept2:hover, .btn-concept2:focus {
    background-color: #ccdb37;
    color: #111;
}

.btn {
    border-radius: 0;
}

.footer  {
    padding: 2em 0;
}

.social-icon {
    color: white;
    font-size: 3em;
    margin-right: 0.5em;
    &:hover {
        color: $green;
    }
}