#map {
    width: 100%;
    height: 700px;
}

#mini {
    width: 100%;
    height: 250px;
    margin-top: 20px;
}


.info {
    width: 330px;
}

.info img {
    border: 0;
}

.info-body {
    width: 100%;
}

.info-comments, .info-owner, .info-owned {
    margin-bottom: 1em;
}

img.info-img {
    max-width: 400px;
    max-height: 350px;
}

.gm-style img.info-img {
    max-width: 200px;
    max-height: 250px;
    display: block;
    margin-bottom: 1em;
}

.gm-style-iw{
    max-height: 400px;
    overflow-y:auto!important;
}


.address-input {
    display: inline-block;
    width: 100%;
    max-width: 400px;
}

.edit-button {
    margin-top: 1em;
}

.checkbox label {
    padding-right: 10px;
}

.success-actions {
    margin-top: 1em;
}

.inline {
    display: inline-block;
}

.social-media-help {
    margin: 1em 0;
}