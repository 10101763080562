header {
    position: relative;
    width: 100%;
    min-height: auto;
    color: #fff;
    background-image: url('/images/cover5.jpeg');
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;

    .logo-40th {
        position:relative;
        top: 60px;
        width: 180px;
        height: 180px;
        margin: 0 auto;
        text-align: center;
    }

    .header-content {
        position: relative;
        width: 100%;
        padding: 100px 15px;


        h1 {
            margin-top: 0;
            margin-bottom: 0;
            text-transform: uppercase;
            font-weight: 700;
            color: #ccc;

        }

        hr {
            margin: 30px auto;
        }

        p, li {
            font-size: 18px;
            line-height: 1.5em;
            font-weight: 300;
            color: #333333;
        }

    }


}

.letter {
    background: rgba(255, 255, 255, 0.85);
    padding: 20px;
    margin-bottom: 30px;
}

.video-link {
    margin-left: 2em;
}

.founder {
    display:inline-block;
    width:40%;
    min-width: 200px;
    max-width: 400px;

    p {
        white-space: nowrap;
    }
}

@media(min-width:768px) {
    header {
        min-height: 894px;
        height: 100%;
    }

    header .header-content {
        position: absolute;
        top: 50%;
        position: relative;
        top: 400px;
        padding: 0 50px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    header .header-content .header-content-inner {
        margin-right: auto;
        margin-left: auto;
        max-width: 850px;
    }

    .selected:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid $green;
        position: relative;
        top: 0px;
        float: left;
        margin-left: 70px;
    }

}

.slideshow {
    padding: 40px 0;
    background-color: #222;
}

.video-intro {
    background-color: #eee;
    padding: 30px 0;

    p {
        font-size: 20px;
        margin: 25px;
        line-height: 50px;
        text-align: center;
    }
}

.section-video-thumbnails {
    padding: 20px 0 0;
    background-color: white;
}

ul.video-thumbnails {
    list-style-type: none;
    text-align: center;
    padding: 0;

    li {
      margin: 0 20px;
      display: inline-table;
      display:pointer;
      max-width: 175px;
  }

  p {
    margin-top: 0;
    margin-bottom: 9px;
    color:#000;
    font-size: 18px;
    color: $dark-blue;
}

}


.video {
    width: 100%;
    height: 400px;
    margin-right: 0%;
    position: relative;
    opacity: 1;
    display: none;
    z-index: 1;;
    background-position: center;
    background-size: cover;
}

.video-clip {
    display: none;
}

#video-one {
    background-image: url(/images/video-covers/why-vermont.jpeg);
    display:block;
}

#video-two {
    background-image: url(/images/video-covers/what-concept2-means.jpeg);
}

#video-three {
    background-image: url(/images/video-covers/erg-evolution.jpeg);
}

#video-four {
    background-image: url(/images/video-covers/terry-smythe.jpg);
    background-position-y: 0%;
}

#video-five {
    background-image: url(/images/video-covers/volker-nolte.jpg);
}

.caption {
    font-size: 15px;
    color: #eee;
}

.controls {
    font-size: 20px;
    color: #eee;
    margin-top: 20px;
}

.video-cover {
    position: relative;
    display: block;
    top: 10px;

    .video-cover-text {
        margin: 0 auto;
        padding: 0px 0 90px;
    }

    p {
      font-size: 50px;
      line-height: 50px;
      text-align: center;
      margin-bottom: 30px;
  }
}

.play-vid{
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 67px;
    width: 67px;
    cursor: pointer;
    text-align: center;
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-image: url(/images/play.png);

    &:hover {
      background-position: -69px 0px;
  }

}

.video-thumb__image {
    width: 175px;
    height: 175px;
    border: 6px solid transparent;
}

.selected .video-thumb__image {
    border: 6px solid $green;
}

.slideshow img {
    display: none;
    max-height: 500px;
}

.slideshow img:first {
    display: block;
}




/*! Gray v1.4.5 (https://github.com/karlhorky/gray) | MIT */
.grayscale {
  /* Firefox 10-34 */
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='saturate' values='0'/></filter></svg>#grayscale");

  /*
    Chrome 19+,
    Safari 6+,
    Safari 6+ iOS,
    Opera 15+
  */
  -webkit-filter: grayscale(1);

  /* Firefox 35+ */
  filter: grayscale(1);

  /* IE 6-9 */
  filter: gray;
}

.grayscale.grayscale-fade {
  transition: filter .5s;
}

/* Webkit hack until filter is unprefixed */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .grayscale.grayscale-fade {
    -webkit-transition: -webkit-filter .5s;
    transition:         -webkit-filter .5s;
  }
}

.grayscale.grayscale-off,
.grayscale.grayscale-fade:hover {
  -webkit-filter: grayscale(0);
  filter:         grayscale(0);
}

/* Background element */
.grayscale.grayscale-replaced {
  -webkit-filter: none;
  filter:         none;
}

.grayscale.grayscale-replaced > svg {
  -webkit-transition: opacity .5s ease;
  transition:         opacity .5s ease;
  opacity: 1;
}

.grayscale.grayscale-replaced.grayscale-off > svg,
.grayscale.grayscale-replaced.grayscale-fade:hover > svg {
  opacity: 0;
}
