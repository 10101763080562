$bs-height-base: ($line-height-computed + $padding-base-vertical * 2);
$bs-height-lg:   (floor($font-size-large * $line-height-base) + $padding-large-vertical * 2);
$bs-height-sm:   (floor($font-size-small * 1.5) + $padding-small-vertical * 2);
$bs-height-xs:   (floor($font-size-small * 1.2) + $padding-small-vertical + 1);

.btn-social {
  position: relative;
  padding-left: ($bs-height-base + $padding-base-horizontal);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  > :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: $bs-height-base;
    line-height: ($bs-height-base + 2);
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
  &.btn-lg {
    padding-left: ($bs-height-lg + $padding-large-horizontal);
    > :first-child {
      line-height: $bs-height-lg;
      width: $bs-height-lg;
      font-size: 1.8em;
    }
  }
  &.btn-sm {
    padding-left: ($bs-height-sm + $padding-small-horizontal);
    > :first-child {
      line-height: $bs-height-sm;
      width: $bs-height-sm;
      font-size: 1.4em;
    }
  }
  &.btn-xs {
    padding-left: ($bs-height-xs + $padding-small-horizontal);
    > :first-child {
      line-height: $bs-height-xs;
      width: $bs-height-xs;
      font-size: 1.2em;
    }
  }
  border-radius: 0;
}

.btn-social-icon {
  @extend .btn-social;
  height: ($bs-height-base + 2);
  width: ($bs-height-base + 2);
  padding: 0;
  > :first-child {
    border: none;
    text-align: center;
    width: 100%!important;
  }
  &.btn-lg {
    height: $bs-height-lg;
    width: $bs-height-lg;
    padding-left: 0;
    padding-right: 0;
  }
  &.btn-sm {
    height: ($bs-height-sm + 2);
    width: ($bs-height-sm + 2);
    padding-left: 0;
    padding-right: 0;
  }
  &.btn-xs {
    height: ($bs-height-xs + 2);
    width: ($bs-height-xs + 2);
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin btn-social($color-bg, $color: #fff) {
  background-color: $color-bg;
  @include button-variant($color, $color-bg, rgba(0,0,0,.2));
}


.btn-facebook      { @include btn-social(#3b5998); }
.btn-flickr        { @include btn-social(#ff0084); }
.btn-google        { @include btn-social(#dd4b39); }
.btn-instagram     { @include btn-social(#3f729b); }
.btn-linkedin      { @include btn-social(#007bb6); }
.btn-pinterest     { @include btn-social(#cb2027); }
.btn-twitter       { @include btn-social(#55acee); }
.btn-email         { @include btn-social(#a0b1bc); }