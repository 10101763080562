header.montreal {
    background-image: url('/images/montreal/image11.jpeg');

    .header-content h1 {
        color: #333;
        padding-bottom: 1em; 
    }

}

.montreal-slideshow {
    padding: 50px 0;
    background-color: #222;

    img {
        max-height: 600px;
    }

    .caption {
        margin-top: 25px;
    }
}


.montreal-thanks {
    background-color: white;

    p {
        font-size: 24px;
        padding: 2em;
    }
}
