$light-blue: #7D9BC1;
$dark-blue: #003A70;
$green: #B7BF10;
$grey: #B1B3B3;
$orange: #EAAA00;

$font-path: "/fonts/";
$icon-font-path: "/fonts/bootstrap/";

$font-family-sans-serif:  "ProximaNovaRegular", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-sans-serif-bold:  "ProximaNovaSemibold", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;

$screen-xxs-max: '479px';